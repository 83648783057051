<template>
    <div :class="modalClass" tabindex="-1" id="import-modal" role="dialog" :aria-hidden="!isVisible" :style="style">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Import Sites</h5>
                    <b-button variant="primary" class="close" @click="hideModal()">
                        <bootstrap-icon icon="x"></bootstrap-icon>
                    </b-button>
                </div>
                <div class="modal-body">
                    <b-row>
                        <b-col cols="12">
                            <b-alert variant="warning" v-model="showError" dismissible @dismissed="errorMessage=''"
                                     class="format-message" v-html="errorMessage"></b-alert>
                        </b-col>
                    </b-row>
                    <instructions-alert></instructions-alert>
                    <b-row>
                        <b-col cols="12">
                            <div class="border border-default rounded-2 p-3">
                                <h4 class="mb-3">Import Sites</h4>
                                <div class="mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="skip-first-row"
                                               :checked="skipFirstRow" v-model="skipFirstRow">
                                        <label class="form-check-label" for="skip-first-row">Skip first row
                                            (Headlines)</label>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <b-form-group label="Contract" label-for="contract">
                                        <b-form-select v-model="selectedContract"
                                                       :options="contracts"
                                                       id="contract">
                                            <template #first>
                                                <b-form-select-option :value="''" disabled>-- Please select a contract
                                                    --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="mb-3">
                                    <b-form-group label="PIP" label-for="pip">
                                        <b-form-select v-model="selectedPip"
                                                       :options="pipOptions"
                                                       id="pip">
                                            <template #first>
                                                <b-form-select-option :value="''" disabled>-- Please select a PIP
                                                    --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="mb-3">
                                    <label for="file" class="form-label">Choose a file:</label>
                                    <div class="input-group">
                                        <input
                                            type="file"
                                            id="file"
                                            ref="fileInput"
                                            class="form-control"
                                            @change="handleFileChange"
                                        />
                                        <button type="button" class="btn btn-primary" @click="importFile">Import
                                        </button>
                                    </div>
                                </div>
                                <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                                <br>
                                <result-check-table :upload-successful="uploadSuccessful" @upload-successful="resetForm()"></result-check-table>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {BButton, BCol, BRow} from "bootstrap-vue-3";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import {importSites} from "@/components/siteImporter/service/excelImport";
import {useFiberInfoStore} from "@/store/fiberInfoStore";
import {useSiteImportStore} from "@/components/siteImporter/store/sitesImportStore";
import InstructionsAlert from "@/components/siteImporter/instructionsAlert.vue";
import ResultCheckTable from "@/components/siteImporter/resultCheckTable.vue";
//import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {toast} from "vue3-toastify";
import {mapListToFromSelect} from "@/components/utils/vueBootstrapDataStructureSupport";
import {useFiberPipsStore} from "@/store/fiberPipsStore";

export default defineComponent({
    name: "importModal",
    components: {ResultCheckTable, InstructionsAlert, BootstrapIcon, BButton, BCol, BRow},
    /*async setup() {
        await useFiberPopNodesStore().load()
        await useFiberPipsStore().load()
    },*/
    data() {
        return {
            sitesCsv: "",
            isVisible: false,
            showError: false,
            errorMessage: "",
            selectedFile: null,
            skipFirstRow: true,
            selectedContract: "",
            selectedPip: "",
            uploadSuccessful: false
        }
    },
    computed: {
        modalClass() {
            return "modal modal-primary modal-xl fade " + (this.isVisible ? "show" : "")
        },
        style() {
            return this.isVisible ? "display: block; background: rgba(0,0,0, 0.6); " : ""
        },
        contracts() {
            return useFiberInfoStore().contracts
        },
        pipOptions() {
            return mapListToFromSelect(useFiberPipsStore().pipsList, 'pip', ['name'])
        },
        sites() {
            return useSiteImportStore().sites
        }
    },
    methods: {
        showModal() {
            this.isVisible = true
        },
        hideModal() {
            //reset
            //this.item = reactive({...this.originalData})
            this.isVisible = false
        },
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        importFile() {
            if (!this.selectedFile) {
                this.errorMessage = 'Please select a file.';
                return;
            }

            if (this.selectedContract == "") {
                this.errorMessage = 'Please select a contract first.';
                return;
            }

            if (this.selectedPip == "") {
                this.errorMessage = 'Please select a PIP first.';
                return;
            }

            if (this.getFileExtension(this.selectedFile.name) == "xlsx") {
                importSites(this.selectedFile, this.selectedContract, this.selectedPip)
                    .then((sites) => {

                        useSiteImportStore().storeSites(sites)
                        this.errorMessage = null
                        this.selectedFile = null

                        this.uploadSuccessful = false

                        toast(sites.length + " Sites were imported. You can start the upload now...", {
                            dangerouslyHTMLString: true,
                            position: toast.POSITION.BOTTOM_CENTER,
                            type: toast.TYPE.SUCCESS,
                            icon: true,
                            autoClose: 2000,
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.message
                        useSiteImportStore().storeSites([])
                    })
            }
        },
        getFileExtension(fileName) {
            const parts = fileName.split('.');
            return parts[parts.length - 1].toLowerCase();
        },
        resetForm() {
            this.selectedFile = null
            this.selectedContract = ""
            this.selectedPip = ""
            this.uploadSuccessful = true

            toast( useSiteImportStore().sites.length + " Sites were uploaded to the Database. You will find the new Sites in the map now.", {
                dangerouslyHTMLString: true,
                position: toast.POSITION.BOTTOM_CENTER,
                type: toast.TYPE.SUCCESS,
                icon: true,
                autoClose: 2000,
            });

        }
    }
})
</script>

<style scoped>

</style>
