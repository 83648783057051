
const popColor = (popToken: string) => {
    switch (popToken) {
        case 'planned':
            return "#007bff"
        case 'built':
            return "#28a745"
    }
}

const siteColor = (siteToken: string) => {
    switch (siteToken) {
        case 'reserved':
            return "#444a4f"
        case 'confirmed':
            return "#007bff"
        case 'mounting':
            return "#007bff"
        case 'accepted':
            return "#28a745"
        case 'indelivery':
            return "#dc3545"
        case 'unclear':
            return "#ffc107"
        case 'built':
            return "#28a745"
    }
}

const linkLineColor = (linkToken: string) => {
    switch (linkToken) {
        case 'idle':
            return "#444a4f"
        case 'prepared':
            return "#007bff"
        case 'connected':
            return "#28a745"
        case 'indelivery':
            return "#dc3545"
        case 'unclear':
            return "#ffc107"
    }
}


export {popColor, siteColor, linkLineColor}
