import {defineStore} from 'pinia'
import {ref} from "vue";
import Keycloak from "keycloak-js";

export const useAuthStore = defineStore('auth', () => {
    const token = ref()

    async function tryAuthentication() {

        console.log("trying to authenticate ... ")
        const keycloakInstance = new Keycloak()

        await keycloakInstance.init({
            onLoad: "login-required",
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
        })
            .then(function (authenticated) {
                if (authenticated) {
                    token.value = keycloakInstance.token
                } else {
                    alert("not authenticated");
                }
            })
            .catch((e) => {
                console.dir(e);
                console.log(`keycloak init exception: ${e}`);
            });
    }

    //onMounted(tryAuthentication);

    return {tryAuthentication, token}
})
