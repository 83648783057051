import {myFiberGeoPosition} from "@/models/myFiberGeo";
import {fiberSite, fiberSiteAddress} from "@/models/fiberSite";
import {fiberLink} from "@/models/fiberLink";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {fiberPopNode} from "@/models/fiberPopNode";
import {useFiberPipsStore} from "@/store/fiberPipsStore";

const roundLatLng = function (latlng: myFiberGeoPosition, deicmals: number = 6): myFiberGeoPosition {
    const geoPos = {
        lat: parseFloat(latlng.lat.toFixed(deicmals)),
        lng: parseFloat(latlng.lng.toFixed(deicmals))
    } as myFiberGeoPosition

    return geoPos
}

const extractLatLngAsString = function (geoPosition: myFiberGeoPosition): string {
    return geoPosition.lat + "," + geoPosition.lng
}

const extractContractsFromLinks = function(linksList: fiberLink[]): string[] {
    const contractList = []
    for(const link of linksList) {
        if(!contractList.includes(link.contract)) {
            contractList.push(link.contract)
        }
    }

    return contractList
}
const extractPopNamesFromLinks = function(linksList: fiberLink[]): string[] {
    const popNamesList = []
    for(const link of linksList) {
        if(!popNamesList.includes(link.popName)) {
            popNamesList.push(link.popName)
        }
    }

    return popNamesList
}

const extractPipsFromPipList = function(site: fiberSite) {
    const pipList = []
    for(const pip of useFiberPipsStore().pipsList) {
        if(pip.pip == site.pip) {
            pipList.push(pip.name)
        }
    }

    return pipList
}

const extractPipsFromLinks = function(linksList: fiberLink[]): string[] {
    const pipList = []
    for(const link of linksList) {
        const pop: fiberPopNode = useFiberPopNodesStore().getOne(link.pop_id)
        if(!pipList.includes(pop.pip)){
            pipList.push(pop.pip)
        }
    }

    return pipList
}

const mapTokenToVariant = function (token: string) {
    switch (token) {
        case 'reserved':
            return "secondary" //site
        case 'confirmed':
            return "primary" //site
        case 'mounting':
            return "primary" //site
        case 'accepted':
            return "success" //site
        case 'idle':
            return "secondary" //link
        case 'prepared':
            return "primary" //link
        case 'connected':
            return "success" //link
        case 'indelivery':
            return "danger" //link, site
        case 'unclear':
            return "warning" //link, site
        case 'planned':
            return "primary" //node
        case 'built':
            return "success" //node, site

    }
    return "primary"
}

const mapTokenToIcon = function (token: string) {
    switch (token) {
        case 'reserved':
            return "clipboard2-plus" //site
        case 'confirmed':
            return "clipboard2-check" //site
        case 'mounting':
            return "clipboard2-pulse"//site
        case 'accepted':
            return "check-circle-fill" //site
        case 'idle':
            return "dash-circle-dotted" //link
        case 'prepared':
            return "clipboard2-plus" //link
        case 'connected':
            return "check-circle-fill" //link
        case 'indelivery':
            return "exclamation-triangle"  //link, site
        case 'unclear':
            return "exclamation-octagon"  //link, site
        case 'planned':
            return "clipboard2-plus"  //node
        case 'built':
            return "check-circle-fill" //node, site
    }

    return "grid-fill"
}

const mapAddressShortFormat = function (address: fiberSiteAddress): string {
    if (!address) {
        return ""
    }
    const addresString =
        (address.street != null ? address.street : '')
        + " " + (address.hnr != null ? address.hnr : '')
        + " " + (address.door != null ? address.door : '')
        + ", " + (address.zip != null ? address.zip : '')
        + " " + (address.city != null ? address.city : '')

    return addresString.replace(/,\s$/, "")
}

export {roundLatLng, extractLatLngAsString, extractContractsFromLinks, extractPopNamesFromLinks, extractPipsFromPipList, extractPipsFromLinks, mapTokenToVariant, mapTokenToIcon, mapAddressShortFormat}
