import {createApp} from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
import router from './router'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import * as Sentry from "@sentry/vue";

import 'vue3-toastify/dist/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/bootstrap-support.css'
import {useAuthStore} from "@/store/authStore"

import {useFiberInfoStore} from "@/store/fiberInfoStore";

const app = createApp(App)
//not sure how it is deployed, so we need to check that on next deployment - so far I know: locally I have the variable, and so
//I can check if it was a dev issue or on the server - both servers are "production" as the customer works on testportal.myfiber.at
const envName = process.env.VUE_APP_ENV || "production"

Sentry.init({
    app,
    dsn: "https://7941358dd90c4995814cfc538281a6e7@o586285.ingest.sentry.io/4504956086648832",
    environment: envName,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    tracesSampleRate: 1.0,
});

app.use(router)
app.use(createPinia())
app.use(BootstrapVue3)
app.use(BToastPlugin)

const store = useAuthStore().tryAuthentication()
    .then(() => {
        app.mount('#app')
    })

