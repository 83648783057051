<template>
    <b-row>
        <b-col cols="10">
            <b-alert variant="info" :show="displayHelp" fade>
                <bootstrap-icon icon="question-square-fill"></bootstrap-icon>
                &nbsp;<b>How to import a List of Sites</b> <br><br>
                Here you can upload a *.xlsx file.<br>
                You need to provide exactly the following rows for a correct import in your file:<br>

                <ol>
                    <li>street (i.e. Sponheimer Straße)</li>
                    <li>house number (i.e. 3a)</li>
                    <li>door number (i.e. 5)</li>
                    <li>address addon (i.e. Stiege 2)</li>
                    <li>zip (i.e. 9371)</li>
                    <li>location (i.e. Brückl)</li>
                    <li>Geo Position in decimal degrees (WSG48): Latitude (i.e.: 46.748625)</li>
                    <li>Geo Position in decimal degrees (WSG48): Longitude (i.e.: 14.534478)</li>
                    <li>POP name (i.e. pop_brueckl)</li>
                    <li>Site Token (reserved or confirmed)</li>
                    <li>Link Token (idle, prepared, unclear, connected, indelivery)</li>
                </ol>
                <hr>
                <br>
                <bootstrap-icon icon="info-square-fill"></bootstrap-icon>
                &nbsp;<b>Template</b> <br><br>
                You can download a template here.<br>
                You will find an example Entry in the first data row. Please remove that line.
                <br><br>
                <b-button role="link" href="/assets/import-templates/sites-import.xlsx" variant="info">
                    <bootstrap-icon icon="download"></bootstrap-icon>
                    Download: sites-import.xlsx
                </b-button>
            </b-alert>
        </b-col>
        <b-col cols="2">
            <b-button variant="info" @click="displayHelp=!displayHelp" class="mb-3">
                <bootstrap-icon icon="question-square-fill"></bootstrap-icon>
                {{ displayHelp ? "Hide" : "Show" }} Help
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import {defineComponent} from 'vue'
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import {BAlert, BButton, BCol, BRow} from "bootstrap-vue-3";

export default defineComponent({
    name: "instructionsAlert",
    components: {BootstrapIcon, BButton, BCol, BRow, BAlert},
    data() {
        return {
            displayHelp: false,
        }
    }
})
</script>

<style scoped>

</style>
