<template>
    <div :class="badgeClass">
        <span class="headline">{{headline}}</span>
        <span class="content">{{content}}</span>
    </div>
</template>

<script>
export default {
    name: "bootstrapSplitBadge",
    props: {
        variant: String,
        headline: String,
        content: String
    },
    computed: {
        badgeClass() {
            return `myfiber-badge badge-${this.variant}`;
        }
    }
}
</script>

<style scoped>
.myfiber-badge {
    border: 1px solid #fff;
    border-radius: 0.25rem;
    font-size: 12px;
    line-height: 12px;

    margin: 0;
    padding: 0;
    display: inline-block;
}

.myfiber-badge.badge-primary {
    border-color: #007bff;
}

.myfiber-badge.badge-secondary {
   border-color: #6c757d;
}

.myfiber-badge.badge-success {
    border-color: #28a745;
}

.myfiber-badge.badge-danger {
    border-color: #dc3545;
}

.myfiber-badge.badge-warning {
    border-color: #ffc107;
}

.myfiber-badge.badge-info {
    border-color: #17a2b8;
}

.myfiber-badge.badge-light {
    border-color: #f8f9fa;
}

.myfiber-badge.badge-dark {
    border-color: #343a40;
}


.headline {
    display: inline-block;
    padding-right: 16px;

    padding-left: 8px;
    line-height: 14px;
}

.myfiber-badge.badge-primary .headline {
    background-color: #007bff;
    color: #fff;
}

.myfiber-badge.badge-secondary .headline {
    background-color: #6c757d;
    color: #fff;
}

.myfiber-badge.badge-success .headline {
    background-color: #28a745;
    color: #fff;
}

.myfiber-badge.badge-danger .headline {
    background-color: #dc3545;
    color: #fff;
}

.myfiber-badge.badge-warning .headline {
    background-color: #ffc107;
}

.myfiber-badge.badge-info .headline {
    background-color: #17a2b8;
    color: #fff;
}

.myfiber-badge.badge-light .headline {
    background-color: #f8f9fa;
}

.myfiber-badge.badge-dark .headline {
    background-color: #343a40;
}

.content{
    color: #000;
    display: inline-block;
    padding-right: 8px;
    padding-left: 16px;
}
</style>
