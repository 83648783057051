<template>
    <div>

        <b-alert variant="warning" :show="sitesList.length > 0 && !uploadSuccessful">
            <bootstrap-icon icon="exclamation-triangle-fill"></bootstrap-icon>
            &nbsp;Please check the data below befor pressing the "Start Upload" button.

            <b-button v-if="!uploading" variant="warning" @click="startUpload()" class="float-end">
                <bootstrap-icon icon="upload"></bootstrap-icon> Start Upload
            </b-button>
            <b-button v-else variant="default" class="float-end">
                <bootstrap-icon icon="hypnotize" animation="spin"></bootstrap-icon>
            </b-button>
            <span class="clearfix "></span>
        </b-alert>

        <b-alert variant="danger" :show="errorMessage != ''">
            {{errorMessage}}
        </b-alert>

        <b-table :items="sitesList" :fields="fields" responsive striped>
            <template #cell(sid)="row">
                <template v-if="row.item.sid!=null">
                    <b-button variant="success" size="sm" class="pt-1 pb-1"
                              :id="'popover-target-'+row.item.sid"
                              @click="copyToClipboard(row.item.sid, 'Copied SID: ')">
                        <bootstrap-icon icon="check-circle-fill"></bootstrap-icon>
                    </b-button>
                    <b-popover :target="'popover-target-'+row.item.sid" triggers="hover" placement="right">
                        <template #title>Site ID</template>
                        {{ row.item.sid }}
                        <p class="text-muted">Click to Copy to Clipboard</p>
                    </b-popover>
                </template>
                <template v-else>
                    <b-button variant="outline-secondary" size="sm" class="pt-1 pb-1" title="No sid assigned for now">
                        <bootstrap-icon icon="dash-circle-fill" ></bootstrap-icon>
                    </b-button>
                </template>
            </template>
            <template #cell(token)="row">
                <token-badge :token="row.item.token" :small="false"></token-badge>
            </template>
            <template #cell(address)="row">
                <address-cell :address="row.item.address"></address-cell>
            </template>
            <template #cell(pop)="row">
                {{getPopName(row.item.links[0])}}
            </template>
            <template #cell(contract)="row">
                {{row.item.links[0].contract}}
            </template>
            <template #cell(links)="row">
                <bootstrap-split-badge :variant="mapTokenToVariant(row.item.links[0].token)"
                                       :headline="'Fiber '+row.item.links[0].fiber"
                                       :content="row.item.links[0].token"></bootstrap-split-badge>
            </template>
        </b-table>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useSiteImportStore} from "@/components/siteImporter/store/sitesImportStore";
import AddressCell from "@/components/atoms/address-cell.vue";
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import {mapTokenToVariant} from "@/store/util/myFiberDataMapping";
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import {BAlert} from "bootstrap-vue-3";
import {copyToClipboard} from "@/components/utils/usefullUserInteractions";

export default defineComponent({
    name: "resultCheckTable",
    components: {BAlert, TokenBadge, BootstrapSplitBadge, AddressCell, BootstrapIcon},
    emits: ['upload-successful'],
    props: {
        uploadSuccessful: Boolean
    },
    data() {
        return {
            fields: ['sid', 'token', 'center', 'address', 'pop', 'pip', 'contract', 'links'],
            uploading: false,
            errorMessage: "",
        }
    },
    computed: {
        sitesList() {
            return useSiteImportStore().sites
        },
    },
    methods: {
        copyToClipboard,
        mapTokenToVariant, useSiteImportStore,
        getPopName(link) {
            return useFiberPopNodesStore().getPopNameByID(link.pop_id)
        },
        startUpload() {
            this.uploading = true
            this.errorMessage = ""
            useSiteImportStore().uploadSitesAndLinks()
                .then(() => {
                    this.uploading = false
                    this.$emit("upload-successful")
                })
                .catch(error => this.errorMessage = error.message)
        }
    }
})
</script>

<style scoped>

</style>
