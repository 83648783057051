import xlsx, {WorkBook} from 'xlsx';

import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {useFiberLinksStore} from "@/store/fiberLinksStore";
import {fiberLink} from "@/models/fiberLink";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {useDorisSearchStore} from "@/store/dorisSearchStore";
import {siteRowModel} from "@/components/siteImporter/siteRowModel";
import {
    ImportNoRowsFoundError,
    ImportColCountMismatchError,
    ImportPopNotFound, ImportInvalidSiteToken, ImportInvalidLinkToken
} from "@/components/siteImporter/service/ImportErrors";

const importSites = function(file: File, contract: string, pip: string) {

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let sites = []

        reader.onload = (event) => {
            const fileData: any = event.target.result

            try {
                const workbook: WorkBook = xlsx.read(fileData, {type: 'binary'})

                const rows = aggregateRows(workbook, true)
                sites = createSites(rows, contract, pip)
                resolve(sites)
            } catch (error) {
                reject(error)
            }
        }

        reader.onerror = (event) => {
            reject( event.target.error )
        }

        reader.readAsBinaryString(file)
    })
}

function aggregateRows(workbook, ignoreHeader) {
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

    if(jsonData.length == 0) {
        throw new ImportNoRowsFoundError()
    }

    // Remove the header row if ignoreHeader is true
    if (ignoreHeader) {
        jsonData.shift();
    }

    if(jsonData.length == 0) {
        throw new ImportNoRowsFoundError()
    }

    if(typeof (jsonData[0][10]) === "undefined" ) {
        throw new ImportColCountMismatchError()
    }

    const rowList: siteRowModel[] = [];

    try {
        for (const row of jsonData) {
            const siteRow: siteRowModel = {
                street: row[0],
                houseNumber: row[1],
                doorNumber: row[2],
                addressAddon: row[3],
                zip: row[4],
                location: row[5],
                lat: row[6],
                lng: row[7],
                popName: row[8],
                siteToken: row[9],
                linkToken: row[10]
            }
            rowList.push(siteRow)
        }

        return rowList;
    } catch (e) {
        throw new ImportColCountMismatchError()
    }

}

function createSites(rows: siteRowModel[], contract: string, pip: string) {

    const sites = []
    let index = 1
    for (const row of rows) {
        try{
            validateTokens(row, index)
        } catch (error) {
            throw new Error(error)
        }

        const zeroPosition = {
            lat: 0,
            lng: 0
        }

        const newSite = useFiberSitesStore().getEmptySite(zeroPosition)

        newSite.address.street = row.street
        newSite.address.hnr = row.houseNumber
        newSite.address.door = row.doorNumber
        newSite.address.addon = row.addressAddon
        newSite.address.zip = row.zip
        newSite.address.city = row.location

        newSite.center = row.lat +","+row.lng
        newSite.token = row.siteToken
        newSite.pip = pip

        //interims sid, will be replaced after site creation
        const newLink: fiberLink = addLink("fakesid", row.popName, row.linkToken, contract)
        newSite.links = [newLink]

        sites.push(newSite)
        index++
    }

    return sites
}

function validateTokens(siteRow: siteRowModel, index: number): void {
    const allowedSiteTokensAtCreation = ['reserved', 'confirmed']
    const allowedLinkTokensAtCreation = ['idle','unclear','indelivery','prepared','connected']

    if(!allowedLinkTokensAtCreation.includes(siteRow.linkToken)) {
        throw new ImportInvalidLinkToken(siteRow.linkToken, index)
    }

    if(!allowedSiteTokensAtCreation.includes(siteRow.siteToken)) {
        throw new ImportInvalidSiteToken(siteRow.siteToken, index)
    }
}

function addLink(sid, popName, token, contract) {

    try{
        const newLink = useFiberLinksStore().getEmptyLink()
        newLink.sid = sid
        newLink.fiber = "1"
        newLink.token = token
        newLink.contract = contract
        newLink.pop_id = useFiberPopNodesStore().filterBy(popName)[0].pop_id

        return newLink
    } catch (error) {
        throw new ImportPopNotFound(popName)
    }


}
function getGeopos(address) {
    const query = address.zip + " " + address.city + " " + address.street + " " + address.hnr
    useDorisSearchStore().findByQuery(query)

    const result = useDorisSearchStore().searchResult
    return result[0].position
}

export {importSites}
